<template>
  <path
    d="M3 10C3 7.79086 4.79086 6 7 6H23C25.2091 6 27 7.79086 27 10V12.1315C27 12.9302 26.1099 13.4066 25.4453 12.9635L24.5038 12.3359C24.1753 12.1169 23.7893 12 23.3944 12H21H15H9H6.60555C6.2107 12 5.82469 12.1169 5.49615 12.3359L4.5547 12.9635C3.89014 13.4066 3 12.9302 3 12.1315V10Z"
    fill="#3769D6"
  />
  <rect x="6" y="9" width="8" height="6" rx="1" fill="#EDF4FE" />
  <rect x="16" y="9" width="8" height="6" rx="1" fill="#EDF4FE" />
  <rect x="5" y="21" width="2" height="3" rx="1" fill="#92AFEA" />
  <rect x="23" y="21" width="2" height="3" rx="1" fill="#92AFEA" />
  <path
    d="M3 20.6667C3 20.2985 3.29848 20 3.66667 20H26.3333C26.7015 20 27 20.2985 27 20.6667C27 21.403 26.403 22 25.6667 22H4.33333C3.59695 22 3 21.403 3 20.6667Z"
    fill="#3769D6"
  />
  <path
    d="M3 17C3 14.7909 4.79086 13 7 13H23C25.2091 13 27 14.7909 27 17V18C27 18.5523 26.5523 19 26 19H4C3.44772 19 3 18.5523 3 18V17Z"
    fill="#3769D6"
  />
</template>
